const Data = {
    menuItems: [
      {
        name: "Home",
        path: "/",
      },
      {
        name: "Portafolio",
        path: "/#portafolio",
        offset: "-50"
      },
      {
        name: "Contact",
        path: "/#contact",
        offset: "-50"
      },
      {
        name: "Blog",
        path: "/blog",
      }
    ]
  };
  export default Data;
  