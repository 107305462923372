import React from "react";
import { Link } from "gatsby";

import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaEnvelope,
  FaInstagram
} from "react-icons/fa";

import { Container, Row, Col } from "common/src/reusecore/Layout";
import Button from "common/src/reusecore/Button";
import ParticleComponent from "common/src/reusecore/Particle";

import logo from "common/src/assets/images/app/footer/footer-logo-two.png";

import FooterBGTwo from "common/src/assets/images/app/footer/footer-particle-two.png";

import FotterWrapper from "./footer.style";

const fecha = new Date();


const FooterClassic = ({data}) => {
  return (
    <FotterWrapper style={{background: "var(--FormContacto)"}}>
      <img src={FooterBGTwo} alt="img" className="section__particle one" />
      <ParticleComponent />
      <Container>
        <Row>
          <Col xs={12} sm={6} lg={4}>
            <div className="footer-widgets">
              <h3 style={{color: "var(--textBanner)"}} className="widget-title">Aplicaciones que he desarrollado</h3>
              <ul className="widget-catagory">
                <li>
                  <a style={{color:"var(--LinkFooter)"}} target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/mx/app/g20-inc/id1473668292">IOS App</a>
                </li>
                <li>
                  <a style={{color:"var(--LinkFooter)"}} target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.g20">Android App</a>
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={4}>
            <div className="footer-widgets">
              <h3 className="widget-title" style={{color: "var(--textBanner)"}}>Últimas Entradas</h3>
              <ul className="widget-catagory">
              {data.allWordpressPost.edges.slice(0, 4).map(post => (
                <li key={post.node.wordpress_id}>
                  <Link style={{color:"var(--LinkFooter)"}} to={"/"+post.node.slug}>{post.node.title}</Link>
                </li>
               ))}  
              </ul>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={4}>
            <div className="footer-widgets">
              <h3 className="widget-title" style={{color: "var(--textBanner)"}}>Sígueme</h3>
              <ul className="social">
                <li>
                  <a style={{color: "var(--textBanner)"}} target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/AlmaSuarezH">
                    <FaFacebookF />
                  </a>
                </li>
                <li>
                  <a style={{color: "var(--textBanner)"}}  target="_blank" rel="noopener noreferrer" href="https://twitter.com/AlmaJaneiro">
                    <FaTwitter />
                  </a>
                </li>
                <li>
                  <a style={{color: "var(--textBanner)"}} target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/almasuarezh/">
                    <FaInstagram />
                  </a>
                </li>
                <li>
                  <a style={{color: "var(--textBanner)"}} target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/alma-fernanda-suarez-777a32b3/">
                    <FaLinkedinIn />
                  </a>
                </li>
              </ul>
              <p className="copyright-text" style={{color:"var(--LinkFooter)"}}>
                Copyright &#169; {fecha.getFullYear()} 
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </FotterWrapper>
  );
};

export default FooterClassic;
